<div class="transactions">
  @for (_ of [1, 2, 3, 4, 5]; track $index) {
    <div class="item">
      <div class="detail">
        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '48px', height: '48px' }"></ngx-skeleton-loader>
        <div class="ms-2">
          <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '150px', height: '20px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '100px', height: '15px' }"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="right text-end">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '80px', height: '20px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '60px', height: '15px' }"></ngx-skeleton-loader>
      </div>
    </div>
  }
</div>
