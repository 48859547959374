import { Component, OnInit } from '@angular/core';
import { NgxSkeletonLoaderComponent } from 'ngx-skeleton-loader';

@Component({
  selector: 'transactions-skeleton',
  templateUrl: './transactions-skeleton.component.html',
  styleUrls: ['./transactions-skeleton.component.scss'],
  imports: [
    NgxSkeletonLoaderComponent
  ],
  standalone: true
})
export class TransactionsSkeletonComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
